// src/NotAdmin.js

import React from 'react';
import { signOut } from '@firebase/auth';
import { auth } from './firebase';
import { Container, Button, Alert } from 'react-bootstrap';

function NotAdmin() {
  const logout = () => {
    signOut(auth).catch((error) => {
      console.error("Error signing out: ", error);
    });
  }

  return (
    <Container>
      <Alert variant="danger">You are not an agency admin.</Alert>
      <Button onClick={logout}>Logout</Button>
    </Container>
  );
}

export default NotAdmin;
