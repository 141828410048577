// src/Login.js

import React, { useState } from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "./firebase";
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // new state for error message

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setErrorMessage(''); // clear error message on successful login
    } catch (error) {
      console.error(error);
      if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
        setErrorMessage('Invalid credentials.');
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
    }
  };
  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setErrorMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error(error);
      if (error.code === 'auth/user-not-found') {
        setErrorMessage('User not found.');
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs lg="4">
          <h3 className="text-center mb-3">Admin Login</h3>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control 
                type="email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                placeholder="Enter email" 
                required 
              />
            </Form.Group>
  
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control 
                type="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                placeholder="Password" 
                required 
              />
            </Form.Group>
  
            <Button variant="primary" type="submit">
              Log in
            </Button>
            <Button variant="link" onClick={handleResetPassword}>
              Reset Password
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
