// src/UserList.js

import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import { db } from "./firebase";
import { ListGroup, Button } from 'react-bootstrap';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

function UserList({ agencyAccessCode }) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const q = query(collection(db, "userData"), where("accountAuthorized", "==", false), where("agencyAccessCode", "==", agencyAccessCode));
      const querySnapshot = await getDocs(q);
      setUsers(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchUsers();
  }, [agencyAccessCode]);

  const authorizeUser = async (id) => {
    const userRef = doc(db, "userData", id);
    const user = users.find((user) => user.id === id);
    await updateDoc(userRef, { accountAuthorized: true });
    const auth = getAuth();
    await sendPasswordResetEmail(auth, user.email).catch((error) => {
      // Handle any errors here.
      console.error(error);
    });
    setUsers(users.filter((user) => user.id !== id));
  };
  

  const denyUser = async (id) => {
    const userRef = doc(db, "userData", id);
    await updateDoc(userRef, { accountDenied: true });
    setUsers(users.filter((user) => user.id !== id));
  };

  return (
    <ListGroup>
      {users.map((user) => (
        <ListGroup.Item key={user.id} className="d-flex justify-content-between align-items-center">
          <div>{user.name} - {user.email}</div>
          <div>
            <Button variant="success" className="me-2" onClick={() => authorizeUser(user.id)}>Authorize</Button>
            <Button variant="danger" onClick={() => denyUser(user.id)}>Deny</Button>
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

export default UserList;
